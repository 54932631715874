import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_8 = { class: "card-body p-9" }
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "d-flex flex-wrap py-5" }
const _hoisted_12 = { class: "flex-equal me-5" }
const _hoisted_13 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_14 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_15 = { class: "text-gray-800 min-w-200px" }
const _hoisted_16 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_17 = { class: "text-gray-800 min-w-200px" }
const _hoisted_18 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_19 = { class: "text-gray-800" }
const _hoisted_20 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_21 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_22 = { class: "flex-equal me-5" }
const _hoisted_23 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_24 = { class: "text-gray-400" }
const _hoisted_25 = { class: "text-gray-800" }
const _hoisted_26 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_27 = { class: "text-gray-800 min-w-200px" }
const _hoisted_28 = { class: "card-body p-9" }
const _hoisted_29 = { class: "mb-10" }
const _hoisted_30 = { class: "mb-4" }
const _hoisted_31 = { class: "d-flex flex-wrap py-5" }
const _hoisted_32 = { class: "flex-equal me-5" }
const _hoisted_33 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_34 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_35 = { class: "text-gray-800 min-w-200px" }
const _hoisted_36 = { class: "text-gray-400" }
const _hoisted_37 = { class: "text-gray-800" }
const _hoisted_38 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_39 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_40 = { class: "text-gray-400" }
const _hoisted_41 = { class: "text-gray-800" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = { class: "text-gray-400" }
const _hoisted_44 = { class: "text-gray-800" }
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { class: "text-gray-400" }
const _hoisted_47 = { class: "text-gray-800" }
const _hoisted_48 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_49 = { class: "text-gray-800 min-w-200px" }
const _hoisted_50 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_51 = { class: "text-gray-800 min-w-200px" }
const _hoisted_52 = { class: "flex-equal me-5" }
const _hoisted_53 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_54 = { class: "text-gray-400" }
const _hoisted_55 = { class: "text-gray-800" }
const _hoisted_56 = { class: "text-gray-400" }
const _hoisted_57 = { class: "text-gray-800" }
const _hoisted_58 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_59 = { class: "text-gray-800 min-w-200px" }
const _hoisted_60 = ["href"]
const _hoisted_61 = { key: 0 }
const _hoisted_62 = { class: "text-gray-400" }
const _hoisted_63 = { class: "text-gray-800" }
const _hoisted_64 = { key: 1 }
const _hoisted_65 = { class: "text-gray-400" }
const _hoisted_66 = { class: "text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.can('read', 'loan-calculator'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.translate('financeFundsRequestOverview')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  to: "/manage/finance-request/finance-funds-request-listing",
                  class: "btn btn-light me-3"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('backToList')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.translate('basicInfo')) + ":", 1),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("table", _hoisted_13, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.translate('fullName')) + ": ", 1),
                      _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.financesFundsRequest.name), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.translate('salary')) + ": ", 1),
                      _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.financesFundsRequest.salary), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.translate('client')) + ": ", 1),
                      _createElementVNode("td", _hoisted_19, [
                        (_ctx.financesFundsRequest.isBankCustomer)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.translate('yes')), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.translate('no')), 1))
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("table", _hoisted_23, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.translate('phone')) + ":", 1),
                      _createElementVNode("td", _hoisted_25, _toDisplayString(_ctx.financesFundsRequest.phone), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.translate('obligations')) + ": ", 1),
                      _createElementVNode("td", _hoisted_27, _toDisplayString(_ctx.financesFundsRequest.obligations), 1)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("h5", _hoisted_30, _toDisplayString(_ctx.translate('propertyInfo')) + ":", 1),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("table", _hoisted_33, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_34, _toDisplayString(_ctx.translate('Amount')) + ": ", 1),
                      _createElementVNode("td", _hoisted_35, _toDisplayString(_ctx.financesFundsRequest.amount), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_36, _toDisplayString(_ctx.translate('isSent')) + ":", 1),
                      _createElementVNode("td", _hoisted_37, [
                        (_ctx.financesFundsRequest.isSent)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.translate('yes')), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(_ctx.translate('no')), 1))
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_40, _toDisplayString(_ctx.translate('source')) + ":", 1),
                      _createElementVNode("td", _hoisted_41, _toDisplayString(_ctx.financesFundsRequest.screen), 1)
                    ]),
                    (
                    _ctx.financesFundsRequest.res && _ctx.financesFundsRequest.res.LeadID
                  )
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_42, [
                          _createElementVNode("td", _hoisted_43, _toDisplayString(_ctx.translate('LeadID')) + ":", 1),
                          _createElementVNode("td", _hoisted_44, _toDisplayString(_ctx.financesFundsRequest.res.LeadID), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (
                    _ctx.financesFundsRequest.res && _ctx.financesFundsRequest.res.status
                  )
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_45, [
                          _createElementVNode("td", _hoisted_46, _toDisplayString(_ctx.translate('resStatus')) + ":", 1),
                          _createElementVNode("td", _hoisted_47, _toDisplayString(_ctx.financesFundsRequest.res.status), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_48, _toDisplayString(_ctx.translate('repaymentPeriod')) + ": ", 1),
                      _createElementVNode("td", _hoisted_49, _toDisplayString(_ctx.financesFundsRequest.repaymentPeriod), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_50, _toDisplayString(_ctx.translate('createdAt')) + ": ", 1),
                      _createElementVNode("td", _hoisted_51, _toDisplayString(new Date(_ctx.financesFundsRequest.createdAt)
                        .toLocaleString('en-GB')
                        .split(',')[0]), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("table", _hoisted_53, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_54, _toDisplayString(_ctx.translate('propertyCategory')) + ": ", 1),
                      _createElementVNode("td", _hoisted_55, _toDisplayString(_ctx.financesFundsRequest.property_category &&
                      _ctx.financesFundsRequest.property_category.titleEn
                        ? _ctx.financesFundsRequest.property_category.titleEn
                        : ''), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_56, _toDisplayString(_ctx.translate('propertyValue')) + ": ", 1),
                      _createElementVNode("td", _hoisted_57, _toDisplayString(_ctx.financesFundsRequest.propertyValue), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_58, _toDisplayString(_ctx.translate('siteUrl')) + ": ", 1),
                      _createElementVNode("td", _hoisted_59, [
                        _createElementVNode("a", {
                          href: _ctx.financesFundsRequest.siteUrl,
                          target: "_blank"
                        }, _toDisplayString(_ctx.financesFundsRequest.siteUrl), 9, _hoisted_60)
                      ])
                    ]),
                    (
                    _ctx.financesFundsRequest.res &&
                    _ctx.financesFundsRequest.res.LeadCode
                  )
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_61, [
                          _createElementVNode("td", _hoisted_62, _toDisplayString(_ctx.translate('leadCode')) + ":", 1),
                          _createElementVNode("td", _hoisted_63, _toDisplayString(_ctx.financesFundsRequest.res.LeadCode), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (
                    _ctx.financesFundsRequest.res &&
                    _ctx.financesFundsRequest.res.statusText
                  )
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_64, [
                          _createElementVNode("td", _hoisted_65, _toDisplayString(_ctx.translate('resStatusText')) + ": ", 1),
                          _createElementVNode("td", _hoisted_66, _toDisplayString(_ctx.financesFundsRequest.res.statusText), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}